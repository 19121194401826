import { Form as FormComponent } from "antd";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Empty from "antd/lib/empty";
import Typography from "antd/lib/typography";
import { Field, Formik } from "formik";
import { navigate } from "gatsby";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import {
  editAddress,
  removeAddress,
  setDefault,
} from "../../../state/addresses";
import { ValidationSchema, fields, initialValues } from "./formFields";

const { Text } = Typography;

const AddressCard = ({ address }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [editing, setEditing] = useState(false);
  const [buttonState, setButtonState] = useState({});

  const addressesData = useSelector((state) => {
    return state.addresses.addressInfo;
  });

  if (!addressesData.length)
    return (
      <Empty description={t("saved.noItems")}>
        <Button onClick={() => navigate("/app/addressmanager")}>
          {t("profile.address.add")}
        </Button>
      </Empty>
    );

  return (
    <Formik
      initialValues={address || initialValues}
      validationSchema={ValidationSchema}
      onSubmit={async (values) => {
        switch (buttonState) {
          case "remove":
            dispatch(removeAddress(values));
            break;
          case "finishEdit":
            dispatch(editAddress(values));
            break;
          case "default":
            dispatch(setDefault(values));
            break;
          default:
            console.log("error");
        }
      }}
    >
      {({ submitForm, values, errors, touched }) => {
        return (
          <Card
            style={{ border: "1px solid #DDDDDD", marginBottom: 20 }}
            title={values.name}
            extra={
              <>
                {addressesData[0].name === address.name ? (
                  <Text strong>{t("address.default")}</Text>
                ) : (
                  <Button
                    onClick={() => {
                      setButtonState("default");
                      submitForm();
                    }}
                  >
                    {t("address.setDefault")}
                  </Button>
                )}
              </>
            }
            actions={[
              editing === false && (
                <Button
                  onClick={() => {
                    setButtonState("remove");
                    submitForm();
                  }}
                >
                  {t("address.remove")}
                </Button>
              ),
              editing === false ? (
                <Button
                  onClick={() => {
                    setButtonState("editing");
                    setEditing(true);
                  }}
                >
                  {t("address.edit")}
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setButtonState("finishEdit");
                    setEditing(false);
                    submitForm();
                  }}
                >
                  {t("address.finishEditing")}
                </Button>
              ),
            ]}
          >
            <FormComponent layout="vertical">
              {fields.map((field) => {
                const { name, label, component, required, arr, width } = field;
                const validation = () => {
                  if (errors[name] && touched[name]) return `warning`;
                  else if (!errors[name] && touched[name]) return `success`;
                  return null;
                };
                return (
                  <FormComponent.Item
                    label={editing && t(label)}
                    hasFeedback={editing}
                    required={editing && required}
                    validateStatus={editing && validation()}
                    help={
                      editing && errors[name] && touched[name] && errors[name]
                    }
                    key={name}
                    style={{
                      display: `inline-block`,
                      width,
                      pointerEvents: !editing && `none`,
                      padding: 5,
                    }}
                  >
                    <Field
                      submitted={!editing}
                      name={name}
                      placeholder={t(label)}
                      as={component}
                      arr={arr}
                    />
                  </FormComponent.Item>
                );
              })}
            </FormComponent>
          </Card>
        );
      }}
    </Formik>
  );
};

export default AddressCard;
