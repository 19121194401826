import { Card } from "antd";
import { Link } from "gatsby";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import AddressCard from "./AddressCard";

const AddressDefault = () => {
  const { t } = useTranslation();
  const defaultAddress = useSelector((state) => state.addresses.addressInfo[0]);
  return (
    <Card
      title={t("profile.address.shippingAddress")}
      extra={
        <Link to="app/addressmanager">
          {t(defaultAddress ? "profile.seeAll" : "profile.address.add")}
        </Link>
      }
    >
      <AddressCard address={defaultAddress} />
    </Card>
  );
};

export default AddressDefault;
