import { gql } from "apollo-boost";

export const customerFragment = gql`
  fragment Profile on Customer {
    id
    customerNo
    name
    email
    phoneNumber
    picture
    addresses {
      city
      country
      line1
      line2
      postcode
      state
      isDefault
    }
    birthdate
    country
    gender
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      ...Profile
    }
  }
  ${customerFragment}
`;

export const GET_CUSTOMER = gql`
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      ...Profile
    }
  }
  ${customerFragment}
`;
