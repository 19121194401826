import { Card } from "antd";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { GET_CUSTOMER, UPDATE_CUSTOMER } from "../../graphql/customer";
import styles from "./css/profile.module.scss";

const Input = (props) => {
  return (
    <div className={styles.field}>
      <label className={styles.label}>{props.label}</label>
      <input className={styles.info} {...props} />
    </div>
  );
};

const Sub = (props) => {
  return (
    <div className={styles.userId}>
      <label className={styles.label}>{props.label}:</label>
      <input className={styles.info} {...props} />
    </div>
  );
};

const UserInfo = () => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [buttonState, setButtonState] = useState({});
  const [userAttr, setUserAttr] = useState({});
  const [userId, setUserId] = useState("");

  const myFunction = async (id) => {
    if (id) {
      try {
        const res = await API.graphql({
          query: GET_CUSTOMER,
          variables: { id },
        });
        setUserAttr(res.data.getCustomer);
      } catch (err) {
        console.log(err);
      }
    }
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((res) => {
        setUserId(res.attributes.sub);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    myFunction(userId);
  }, [userId]);

  return (
    <Card>
      {userAttr ? (
        <div className={styles.userInfo}>
          <Formik
            enableReinitialize
            initialValues={{
              name: userAttr.name,
              phoneNumber: userAttr.phoneNumber,
              customerNo: userAttr.customerNo,
              birthdate: userAttr.birthdate,
              country: userAttr.country,
              gender: userAttr.gender,
            }}
            onSubmit={async (values) => {
              if (buttonState === "editing") {
              } else if (buttonState === "finishEdit") {
                try {
                  const res = await API.graphql({
                    query: UPDATE_CUSTOMER,
                    variables: {
                      input: {
                        id: userId,
                        name: values.name,
                        phoneNumber: values.phoneNumber,
                        birthdate: values.birthdate,
                        country: values.country,
                        gender: values.gender,
                      },
                    },
                  });
                  setUserAttr(res);
                } catch (err) {
                  console.log(err);
                }
              } else {
                console.log("error");
              }
            }}
          >
            <Form>
              <div className={editing === false ? "" : styles.editing}>
                <div className={styles.card}>
                  <h3 className={styles.title}>{t("profile.userInfo")}</h3>
                  <footer className={styles.footer}>
                    {editing === false ? (
                      <button
                        className={styles.button}
                        type="submit"
                        onClick={() => {
                          setButtonState("editing");
                          setEditing(true);
                        }}
                      >
                        {t("profile.input.edit")}
                      </button>
                    ) : (
                      <button
                        className={styles.button}
                        type="submit"
                        onClick={() => {
                          setButtonState("finishEdit");
                          setEditing(false);
                        }}
                      >
                        {t("profile.input.finishEdit")}
                      </button>
                    )}
                  </footer>
                  <div className={styles.columned}>
                    <Field
                      type="text"
                      name="name"
                      as={Input}
                      label={t("profile.input.name")}
                    />
                    <Field
                      type="text"
                      name="phoneNumber"
                      as={Input}
                      label={t("profile.input.phoneNo")}
                    />
                  </div>
                  <div className={styles.columned}>
                    <Field
                      type="text"
                      name="gender"
                      as={Input}
                      label="Gender"
                    />
                    <Field
                      type="text"
                      name="birthdate"
                      as={Input}
                      label="Birth Date"
                    />
                    <Field
                      type="text"
                      name="country"
                      as={Input}
                      label="Country of Residence"
                    />
                  </div>
                  <Field
                    type="text"
                    name="customerNo"
                    as={Sub}
                    label={t("profile.input.refNo")}
                  />
                </div>
              </div>
            </Form>
          </Formik>
        </div>
      ) : (
        t("profile.input.loadingProfile")
      )}
    </Card>
  );
};

export default UserInfo;
