import { useLazyQuery } from "@apollo/react-hooks";
import { Button, Col, Row, Space } from "antd";
import { gql } from "apollo-boost";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";

import ResponsiveTitle from "../../components/ResponsiveTitle";
import { logoutUser } from "../../state/user";
import AddressDefault from "./address/AddressDefault";
// import WalletDefault from "./wallet/WalletDefault";
import styles from "./css/profile.module.scss";
import UserInfo from "./UserInfo";

const Profile = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery({ minWidth: 1224 });

  const [getCustomer] = useLazyQuery(GET_USER, {
    fetchPolicy: `cache-only`,
  });

  useEffect(() => {
    getCustomer();
  }, [getCustomer]);

  return (
    <Space direction="vertical" style={{ width: "100%" }}>
      <ResponsiveTitle>{t("profile.welcome")}</ResponsiveTitle>
      <Row justify={"center"}>
        <Col md={10} className={styles.col}>
          <UserInfo />
        </Col>
        {isDesktop && (
          <>
            <Col md={10} className={styles.col}>
              <AddressDefault />
            </Col>
            {/* <Col md={10} className={styles.col}>
              <WalletDefault />
            </Col> */}
          </>
        )}
      </Row>
      <Row justify={"center"}>
        <Button onClick={() => dispatch(logoutUser())}>
          {t("nav.logout")}
        </Button>
      </Row>
    </Space>
  );
};

export default Profile;

export const GET_USER = gql`
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      customerNo
      name
      email
      phoneNumber
      picture
      gender
      birthdate
      country
      addresses {
        city
        country
        line1
        line2
        postcode
        state
        isDefault
      }
      metadata
      createdAt
      updatedAt
      _type
    }
  }
`;
